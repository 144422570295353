import React from "react";
import "./contact-card.css"
import { Col } from "../../../shared/components/col/col";

type Props = {
    image: string;
    text: string;
    onClick: () => void;
}
export const ContactCard = ({image, text, onClick}: Props) => {
    const getBackground = () => {
        switch (image) {
            case 'mail.png' :
                return 'blue-background'
            case 'telephone.png' :
                return 'gray-background'
            case 'whatsapp.png' :
                return 'green-background'
        }

    }

    return (
        <div className={`card-item shiney me-5 ms-5 mb-4 ${getBackground()}`} onClick={onClick}
             style={{cursor: 'pointer'}}>
            <Col className="align-items-center h-100 justify-content-evenly">
                <img src={`/images/contacts/${image}`} alt={"ap metal contact"} width={'80px'}/>
                <div className="mt-4 card-item-text">
                    {text}
                </div>
            </Col>
        </div>
    )
}
