import React from "react";
import { ContactCard } from "./contact-card/contact-card";
import { Subtitle } from "../../shared/components/subtitle/subtitle";
import { Row } from "../../shared/components/row/row";
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';

export const Contact = () => {
    const mailTo = () => {
        window.location.href = "mailto:apmetal24@gmail.com"
    }
    const openPhoneCall = () => {
        window.open('tel:099 435 8662')
    }
    const openWhatsapp = () => {
        window.open(`https://wa.me/+385994358662`, '_blank');
    }

    const cards = [
        {
            imageName: 'mail.png',
            text: 'apmetal24@gmail.com',
            onClick: mailTo,
            delay: 0
        },
        {
            imageName: 'telephone.png',
            text: '099 435 8662',
            onClick: openPhoneCall,
            delay: 200
        },
        {
            imageName: 'whatsapp.png',
            text: 'Pošalji WhatsApp poruku',
            onClick: openWhatsapp,
            delay: 400
        }
    ]

    return (
        <div className="pb-4" id="contact">
            <Subtitle subtitle="Kontakt"/>
            <Row className="justify-content-center align-items-center">
                {cards.map(card =>
                    <ScrollAnimation key={card.imageName} animateIn="bounceInUp" duration={0.5} delay={card.delay}>
                        <ContactCard image={card.imageName} text={card.text} onClick={card.onClick}/>
                    </ScrollAnimation>)}
            </Row>
        </div>
    )
}
