import React, { CSSProperties, ReactNode } from 'react'

type Props = {
    children: ReactNode
    style?: CSSProperties
    className?: string
}
export const Col = ({children, style, className = ''}: Props) => {
    return <div style={style} className={`d-flex ${className} flex-column p-2`}>{children}</div>
}
