import React from "react";
import CountUp from "react-countup";
import './about-us.css'
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';
import ShowMoreText from "react-show-more-text";
import { Subtitle } from "../../shared/components/subtitle/subtitle";
import { Row } from "../../shared/components/row/row";
import { Col } from "../../shared/components/col/col";
import { useScreenWidth } from "../../shared/hooks/screen-info";

export const AboutUs = () => {
    const {isMobileView} = useScreenWidth()
    const IMAGE_SIZE = isMobileView ? "35px" : "75px"
    const ITEM_WIDTH_HEIGHT = isMobileView ? "120px" : "250px"
    const ITEM_FONT_SIZE = isMobileView ? "1.5rem" : "3rem"
    const ITEM_TEXT_SIZE = isMobileView ? "1rem" : "1.3rem"

    const statistic = [
        {
            image: './images/countup/client.png',
            countUpNumber: 5,
            countUpDuration: 1,
            text: 'Zadovoljnih klijenata'
        },
        {
            image: './images/countup/project.png',
            countUpNumber: 20,
            countUpDuration: 1,
            text: 'Projekata'
        },
        {
            image: './images/countup/screw.png',
            countUpNumber: 10000,
            countUpDuration: 2,
            text: 'Izrađenih dijelova'
        }]

    return (
        <div id="about">
            <Subtitle subtitle="O nama"/>
            <div className="container">
                <Row>
                    <div className="p-4" style={{flex: 1}}>
                        <ScrollAnimation animateIn="fadeInUp" duration={0.5}>
                            <ShowMoreText
                                lines={6}
                                more="Prikaži više"
                                less="Prikaži manje"
                                className="text"
                            >
                                <p>
                                    AP-Metal je mlada i dinamična firma koja svojim stručnim pristupom i posvećenošću
                                    pruža vrhunske usluge u strojnoj obradi materijala. Osnovani smo 2024 godine sa
                                    sjedištem
                                    u Vinkovcima.
                                    AP-Metal se ističe u industriji glodanja kao neprikosnovena opcija za sve vaše
                                    potrebe. Naša strast prema preciznosti i kvaliteti odražava se u svakom komadu koji
                                    stvaramo. Svojom ambicijom za neprestanim unapređenjem, AP-Metal je pouzdan partner
                                    koji uvijek teži novim granicama izvrsnosti. Bez obzira na zahtjevnost projekta,
                                    AP-Metal
                                    je vaš siguran odabir za vrhunsku strojnu obradu.
                                </p>
                            </ShowMoreText>
                        </ScrollAnimation>
                    </div>
                    <div className="p-4" style={{flex: 1}}>
                        <ScrollAnimation animateIn="fadeInUp" duration={0.5}>
                            <ShowMoreText
                                lines={6}
                                more="Prikaži više"
                                less="Prikaži manje"
                                className="text"
                            >
                                Naš tim stručnjaka posvećen je ispunjavanju i najzahtjevnijih zahtjeva naših
                                klijenata, kombinirajući inovativne tehnike s vrhunskom tehnologijom kako bismo
                                osigurali
                                optimalne rezultate. Kroz našu stalnu posvećenost istraživanju i razvoju, AP-Metal
                                ostaje u
                                samom vrhu tehnoloških trendova, omogućujući nam da pružimo najnaprednije i
                                najefikasnije
                                rješenje za svaki projekt. Naša posvećenost kvaliteti i pouzdanosti čini nas prvim
                                izborom za klijente koji traže vrhunsku strojnu obradu. Uz AP-Metal, vaši projekti
                                su u sigurnim rukama, a vaše ambicije su naša snaga.
                            </ShowMoreText>
                        </ScrollAnimation>
                    </div>
                </Row>
                <div className="d-flex justify-content-around countups mb-5">
                    {statistic.map((statisticItem) => (
                        <Col style={{width: ITEM_WIDTH_HEIGHT, height: ITEM_WIDTH_HEIGHT}}
                             className="align-items-center justify-content-center statistic-item">
                            <img src={statisticItem.image} alt="ap metal" height={IMAGE_SIZE}/>
                            <span>
                                    <CountUp style={{fontSize: ITEM_FONT_SIZE}} enableScrollSpy
                                             end={statisticItem.countUpNumber}
                                             duration={statisticItem.countUpDuration}/>
                                    <span style={{fontSize: ITEM_FONT_SIZE}}>+</span></span>
                            <p style={{fontSize: ITEM_TEXT_SIZE}}>{statisticItem.text}</p>
                        </Col>
                    ))}
                </div>
            </div>
        </div>
    )
}
