import React from "react";

type Props = {
    name: string;
    width?: string;
}
export const VideoItem = ({width = '100%', name}: Props) => {
    return (
        <div style={{width: width}}>
            <video className={'object-fit-cover'} muted width={'100%'} height={'100%'} autoPlay loop>
                <source src={`videos/${name}`} type="video/mp4"/>
            </video>
        </div>
    )
}
