import React from "react";
import './list-item.css'
import { useScreenWidth } from "../../shared/hooks/screen-info";

type Props = {
    name: string
    isLast: boolean
    href: string
}
export const ListItem = ({name, isLast, href}: Props) => {
    const {isMobileView} = useScreenWidth()

    const onClick = () => {
        document.getElementById(href)?.scrollIntoView({behavior: 'smooth'});
    }

    return (
        <li className={`ms-2 nav-item ${isLast && !isMobileView ? 'ms-auto' : ''}`} style={{cursor: "pointer"}}>
            <div className="nav-link active" aria-current="page" onClick={onClick}>{name}</div>
        </li>
    )
}
