import React from "react";
import { Row } from "../shared/components/row/row";
import { Col } from "../shared/components/col/col";
import './footer.css'
import { useScreenWidth } from "../shared/hooks/screen-info";

export const Footer = () => {
    const {isMobileView} = useScreenWidth()
    const onClick = (href: string) => {
        document.getElementById(href)?.scrollIntoView({behavior: 'smooth'});
    }

    const subtitleContent = <div
        className={`d-flex justify-content-around align-items-center ${isMobileView ? 'mt-4' : ''}`}>
        <Col className={`${isMobileView ? '' : 'align-items-baseline'} gap-2 me-2`}
             style={{flex: isMobileView ? 1 : 'unset', transform: 'translateY(-15px)'}}>
            <div className="footer-subtitle" onClick={() => onClick('home')}>Početak</div>
            <div className="footer-subtitle" onClick={() => onClick('about')}>O nama</div>
            <div className="footer-subtitle" onClick={() => onClick('services')}>Naše usluge</div>
        </Col>
        <div className="vl"/>
        <Col className={`${isMobileView ? '' : 'align-items-baseline'} gap-2 ms-2`}
             style={{flex: isMobileView ? 1 : 'unset'}}>
            <div className="footer-subtitle" onClick={() => onClick('ourWorks')}>Naši radovi</div>
            <div className="footer-subtitle" onClick={() => onClick('location')}>Gdje se nalazimo</div>
            <div className="footer-subtitle" onClick={() => onClick('contact')}>Kontakt</div>
        </Col>
    </div>

    return <footer className="position-relative">
        <div
            style={{padding: isMobileView ? '4rem 0' : '8rem 0'}}
            className={`d-flex ${isMobileView ? 'flex-column' : 'flex-row'} first-row dark-background justify-content-around`}>
            <Col>
                <img src="images/ap-logo-bijeli.png" alt="ap metal logo bijeli"/>
                <div className='text-white'>
                    <div>
                        <span style={{fontWeight: "bold"}}>AP-Metal</span><br/>
                        Obrt za strojnu obradu metala<br/>

                    </div>
                    <div className="mt-4">
                        <span style={{fontWeight: "bold"}}>Adresa:</span><br/>
                        Ul. Dragutina Žanića Karle 16, 32100, Vinkovci
                    </div>
                </div>
            </Col>
            {subtitleContent}
        </div>
        <Row className="justify-content-center">
            <div>
                <span className="me-2 text-dark">Autorska prava ©2024 Sva prava pridržana</span>
                <a className="text-dark">www.ap-metal.hr</a>
            </div>
        </Row>
    </footer>
}
