import React, { useState } from "react";
import './concept-line.css'
import { Subtitle } from "../../shared/components/subtitle/subtitle";
import { Row } from "../../shared/components/row/row";
import { HorizontalLine } from "../../shared/components/horizontal-line/horizontal-line";
import ScrollAnimation from 'react-animate-on-scroll';

enum StageEnum {
    Idea = "idea",
    Draft = "draft",
    Prototype = "prototype",
    Manufacturing = "manufacturing"
}

export const ConceptLine = () => {
    const ideaText = "U fazi ideje, fokus je na generiranju inovativnih koncepta i strategija za osmišljavanje proizvoda ili usluga u području strojne obrade metala. Ovdje se istražuju tržišne potrebe, analiziraju konkurenti i razvijaju temeljne ideje koje će biti osnova za daljnji razvoj.";
    const draftText = "U fazi nacrta, ideje se pretvaraju u konkretne planove i dizajne proizvoda ili usluga. Za strojnu obradu metala, ovo uključuje detaljno inženjersko crtanje, CAD modeliranje, analizu materijala i izradu tehničkih specifikacija.";
    const prototypeText = "Prototipiranje uključuje izradu funkcionalnih modela ili uzoraka proizvoda kako bi se testirale njihove performanse u stvarnom okruženju. Za strojnu obradu metala, ovo može uključivati izradu prototipnih dijelova ili komponenti kako bi se provjerila kvaliteta i pouzdanost.";
    const manufacturingText = "Izrada označava fazu u kojoj se proizvodni proces pokreće kako bi se proizvela finalna roba ili pružila konačna usluga. U kontekstu strojne obrade metala, ovo uključuje upotrebu CNC strojeva, strojeva za rezanje, oblikovanje i zavarivanje kako bi se stvorili konačni dijelovi ili proizvodi.";

    const [activeStage, setActiveStage] = useState(StageEnum.Idea)

    const getStageText = (stage: StageEnum) => {
        switch (stage) {
            case StageEnum.Idea:
                return ideaText;
            case StageEnum.Draft:
                return draftText
            case StageEnum.Prototype:
                return prototypeText;
            case StageEnum.Manufacturing:
                return manufacturingText;
            default:
                return ideaText;
        }
    }
    const showConceptText = (stageEnum: StageEnum) => {
        setActiveStage(stageEnum)
    }

    return <div className="container mb-4" id="services">
        <Subtitle subtitle="Naše usluge"/>
        <Row className="d-flex justify-content-center align-items-center gap-2">
            <ScrollAnimation animateIn="slideInLeft" duration={0.5}>
                <div className={`stage-container ${activeStage === StageEnum.Idea ? 'scale' : ''}`}
                     onMouseEnter={() => showConceptText(StageEnum.Idea)}>
                    <img
                        src="/images/concept/bulb.png" alt="ideja" width="120px" height="120px"/>
                    <div
                        className={`handwriting fst-italic ${activeStage === StageEnum.Idea ? 'underline-active' : ''}`}>Ideja
                    </div>
                </div>
            </ScrollAnimation>
            <HorizontalLine/>
            <ScrollAnimation animateIn="slideInDown" duration={0.5}>
                <div className={`stage-container ${activeStage === StageEnum.Draft ? 'scale' : ''}`}
                     onMouseEnter={() => showConceptText(StageEnum.Draft)}>
                    <img src="/images/concept/write.png" alt="nacrt" width="120px" height="120px"/>
                    <div
                        className={`handwriting fst-italic ${activeStage === StageEnum.Draft ? 'underline-active' : ''}`}>Nacrt
                    </div>
                </div>
            </ScrollAnimation>
            <HorizontalLine/>
            <ScrollAnimation animateIn="slideInUp" duration={0.5}>
                <div className={`stage-container ${activeStage === StageEnum.Prototype ? 'scale' : ''}`}
                     onMouseEnter={() => showConceptText(StageEnum.Prototype)}>
                    <img src="/images/concept/prototype1.png" alt="prototip" width="120px" height="120px"/>
                    <div
                        className={`handwriting fst-italic ${activeStage === StageEnum.Prototype ? 'underline-active' : ''}`}>Prototip
                    </div>
                </div>
            </ScrollAnimation>
            <HorizontalLine/>
            <ScrollAnimation animateIn="slideInRight" duration={0.5}>
                <div className={`stage-container ${activeStage === StageEnum.Manufacturing ? 'scale' : ''}`}
                     onMouseEnter={() => showConceptText(StageEnum.Manufacturing)}>
                    <img src="/images/concept/manufacturing.png" alt="izrada" width="120px" height="120px"/>
                    <div
                        className={`handwriting fst-italic ${activeStage === StageEnum.Manufacturing ? 'underline-active' : ''}`}>Izrada
                    </div>
                </div>
            </ScrollAnimation>
        </Row>
        {activeStage &&
            <Row>
                <span className="fst-italic" style={{transition: '0.5s'}}>{getStageText(activeStage)}</span>
            </Row>
        }
    </div>
}
