import React from "react";
import './navbar.css'
import { Logo } from "./logo/logo";
import { ListItem } from "./list-item/list-item";
import 'bootstrap/dist/js/bootstrap.bundle.js';

export const Navbar = () => {
    const tabs = [
        {title: 'Početak', href: 'home'},
        {title: 'O nama', href: 'about'},
        {title: 'Naši radovi', href: 'ourWorks'},
        {title: 'Naše usluge', href: 'services'},
        {title: 'Gdje se nalazimo', href: 'location'},
        {title: 'Kontakt', href: 'contact'}
    ]
    return <>
        <nav className="navbar navbar-expand-lg bg-light">
            <div className="container">
                <Logo/>
                <button
                    className="navbar-toggler border-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarToggler"
                    aria-controls="navbarToggler"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >

                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse ms-2" id="navbarToggler">
                    <ul className="navbar-nav mb-2 mb-lg-0 w-100">
                        {tabs.map((tab, index) => {
                                return <ListItem key={tab.title} name={tab.title} isLast={index === tabs.length - 1}
                                                 href={tab.href}/>
                            }
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    </>
}
