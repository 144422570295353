import React from "react";

type Props = {
    imageName: string;
    text: string
}
export const SlideItem = ({imageName, text}: Props) => {
    return (
        <div style={{
            backgroundImage: `url(images/our_works/${imageName})`,
            backgroundSize: 'cover',
            width: '300px',
            height: '400px',
            borderRadius: '6px', cursor: 'pointer'
        }}/>
    )
}



