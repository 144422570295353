import React, {ReactNode} from 'react'
import './row.css'

type Props = {
    children: ReactNode
    className?: string
}
export const Row = ({children, className = ''}: Props) => {
    return <div className={`d-flex ${className} row-c p-2`}>{children}</div>
}
