import { Subtitle } from "../../shared/components/subtitle/subtitle";
import React from "react";

export const Location = () => {
    return <div className="bg-light" id="location">
        <Subtitle subtitle={'Gdje se nalazimo'}/>
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2807.097925393194!2d18.794790912605396!3d45.28624287095089!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475c8ae7e65b312b%3A0xf17b43ef4d1089cc!2sPoduzetni%C4%8Dki%20inkubator%20Vinkovci!5e0!3m2!1shr!2shr!4v1712606639470!5m2!1shr!2shr"
            width="100%" height="450" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade">

        </iframe>
    </div>
}
