import {useEffect, useState} from "react";

interface ScreenWidth{
    isMobileView: boolean;
    width: number
}

const BORDER_MOBILE_SCREEN_WIDTH = 630
export const useScreenWidth = () =>{
    const [screenWidth, setScreenWidth] = useState<ScreenWidth>({isMobileView: window.innerWidth <=BORDER_MOBILE_SCREEN_WIDTH, width: window.innerWidth});

    const handleWindowSizeChange = () => {
        setScreenWidth({isMobileView: window.innerWidth <=BORDER_MOBILE_SCREEN_WIDTH, width: window.innerWidth});
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return screenWidth
}
