import React from "react";
import './logo.css'

export const Logo = (props: any) => {
    return (
        <a className="navbar-brand" href="#" {...props}>
            <div className='navbar-logo d-flex'>
                <img className='ap-metal-logo' alt={'ap metal'} src={'images/ap-logo-crni.png'} width={100}
                     height={100}/>
                <div className={'d-flex flex-column justify-content-evenly letters'}>
                    <span>M</span>
                    <span>E</span>
                    <span>T</span>
                    <span>A</span>
                    <span>L</span>
                </div>
            </div>
        </a>
    )
}
