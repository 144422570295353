import { Subtitle } from "../../shared/components/subtitle/subtitle";
import { Row } from "../../shared/components/row/row";
import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { SlideItem } from "./slide-item/slide-item";
import "animate.css/animate.compat.css"
import ScrollAnimation from 'react-animate-on-scroll';
import ShowMoreText from "react-show-more-text";
import { useScreenWidth } from "../../shared/hooks/screen-info";

export const OurWorks = () => {
    const {isMobileView} = useScreenWidth()
    const images = ['cnc1.jpg', 'cnc2.jpg', 'cnc3.jpg', 'cnc4.jpg', 'cnc5.jpg']
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: {max: 4000, min: 3000},
            items: 3
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3
        },
        tablet: {
            breakpoint: {max: 1024, min: 464},
            items: 3
        },
        mobile: {
            breakpoint: {max: 464, min: 0},
            items: 1
        }
    };
    return (
        <div className={'bg-light overflow-hidden pb-4'} id="ourWorks">
            <Subtitle subtitle={'Naši radovi'}/>
            <div className="container bg-light">
                <Row className="justify-content-center d-flex">
                    <ScrollAnimation animateIn="fadeInUp" duration={0.5}>
                        <ShowMoreText
                            lines={6}
                            more="Prikaži više"
                            less="Prikaži manje"
                            className="text"
                        >
                            Izrađujemo dijelove koji nadmašuju očekivanja - od najjednostavnijih do najkompleksnijih
                            oblika. Naša stručnost osigurava vrhunsku kvalitetu svakog proizvoda, pretvarajući najfinije
                            detalje u stvarnost. Bez obzira na vaše potrebe, naša tehnološka rješenja osiguravaju
                            perfekciju u svakom koraku proizvodnog procesa. Od koncepta do realizacije, naša posvećenost
                            detaljima jamči da vaši projekti budu izvedeni s najvišom razinom preciznosti i pouzdanosti.
                        </ShowMoreText>
                    </ScrollAnimation>
                </Row>
                <Carousel
                    responsive={responsive}
                    draggable={isMobileView}
                >
                    {images.map((image: string) =>
                        <div key={image}>
                            <SlideItem key={image} imageName={image} text={'CNC work'}/>
                        </div>
                    )}
                </Carousel>
            </div>
        </div>
    )
}
