import React from "react";

type Props = {
    color?: string
    width?: string
}
export const HorizontalLine = ({color = '#000000', width = '175px'}: Props) => {
    return <hr style={{
        width: width,
        color: '#000000',
        backgroundColor: color,
        height: .5,
        borderColor: '#000000'
    }}/>
}
