import { VideoItem } from "./video-item/video-item";
import React from "react";
import { useScreenWidth } from "../../shared/hooks/screen-info";
import './home.css'

export const Home = () => {
    const {isMobileView} = useScreenWidth()
    const videos = isMobileView ? ['video_3.mp4'] : ['video_1.mp4', 'video_4.mp4', 'video_3.mp4']
    const TitleWrapper = isMobileView ? 'h2' : 'h1'

    const getVideoWidth = (index: number) => {
        if (isMobileView)
            return undefined;
        return index === 1 ? '34%' : '33%'
    }

    return (
        <div className={'d-flex position-relative'} style={{height: 'calc(100vh - 124px)'}} id="home">
            {videos.map((video, index) => (
                <VideoItem key={video} width={getVideoWidth(index)} name={video}/>
            ))}
            <TitleWrapper className={'position-absolute text-white absolute-center title'}>
                {`AP${isMobileView ? '\n' : '-'}METAL`}
            </TitleWrapper>
        </div>
    )
}
