import React from 'react';
import './App.css';
import { Navbar } from "./navbar/navbar";
import { Home } from './body/home/home';
import { OurWorks } from "./body/our-works/our-works";
import { Location } from "./body/location/location";
import { Footer } from "./footer/footer";
import { Contact } from "./body/contact/contact";
import { ConceptLine } from "./body/concept-line/concept-line";
import { AboutUs } from "./body/about-us/about-us";

const App = () => {
    return (
        <div className="app">
            <Navbar/>
            <Home/>
            <AboutUs/>
            <OurWorks/>
            <ConceptLine/>
            <Location/>
            <Contact/>
            <Footer/>
        </div>
    );
}

export default App;
